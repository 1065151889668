import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './bootstrap.css'

import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from 'react-auth-kit';

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <AuthProvider
    authType={'cookie'}
    authName='_auth'
    cookieDomain={window.location.hostname}
    cookieSecure={false}
  >

    <BrowserRouter>
      <App />
      {/* <Imports /> */}
    </BrowserRouter>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
